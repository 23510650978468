@import 'tom-select/dist/css/tom-select';
@import './actiontext.css';
@import 'actiontext';
@import 'trix/dist/trix.css';
@import 'leaflet/dist/leaflet.css';
@import 'leaflet.markercluster/dist/MarkerCluster.css';
@import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
@import 'custom_leaflet_overrides.css';
@tailwind base;
@tailwind components;
@tailwind utilities;

h1 {
  @apply text-3xl sm:text-4xl font-bold text-zinc-700;
}

h2 {
  @apply text-xl sm:text-2xl font-bold text-zinc-700;
}

h3 {
  @apply text-lg sm:text-xl font-bold text-zinc-700;
}

input[type="date"],
input[type="text"],
input[type="number"],
input[type="tel"],
input[type="email"] {
  @apply block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6;
}

input[type="submit"] {
  @apply rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600;
}

select.appearance-none::-ms-expand {
  display: none;
}

select.appearance-none {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
}

input[type="range"]::-webkit-slider-thumb {
  pointer-events: auto;
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  background-color: #4f46e5;
  border-radius: 50%;
  cursor: pointer;
}

input[type="range"]::-moz-range-thumb {
  pointer-events: auto;
  -moz-appearance: none;
  width: 20px;
  height: 20px;
  background-color: #4f46e5;
  border-radius: 50%;
  cursor: pointer;
}

summary::-webkit-details-marker {
  display: none;
}

details[open]+.content-wrapper {
  max-height: 300px;
  border-color: transparent;
  transition: max-height 500ms ease-out;
}

@layer components {
  .badge {
    @apply inline-flex items-center rounded-full px-3 py-1 text-xs font-medium bg-zinc-100 text-gray-800;
  }

  .active_sidebar_tab {
    @apply rounded-lg bg-gray-700 text-white
  }

  .inline-error {
    @apply text-red-400 text-xs m-0 p-0 text-right mb-2;
  }

  .badge-gray {
    @apply badge bg-gray-400 text-stone-50;
  }

  .badge-rose {
    @apply badge bg-rose-500 text-stone-50;
  }

  .badge-amber {
    @apply badge bg-amber-500 text-stone-50;
  }

  .badge-teal {
    @apply badge bg-teal-500 text-stone-50;
  }

  .badge-sky {
    @apply badge bg-sky-500 text-stone-50;
  }

  .badge-purple {
    @apply badge bg-purple-400 text-stone-50;
  }

  .btn {
    @apply bg-zinc-200 text-gray-900 hover:bg-zinc-300 rounded-md px-3 py-2 text-sm font-semibold shadow-sm cursor-pointer disabled:bg-gray-300 disabled:text-white disabled:cursor-not-allowed disabled:hover:bg-gray-300 disabled:active:bg-gray-300 disabled:active:outline-none;
  }

  .btn-indigo {
    @apply btn text-stone-50 bg-indigo-600 hover:text-stone-50 hover:bg-indigo-700 active:bg-indigo-700 active:outline active:outline-4 active:outline-indigo-200;
  }

  .btn-yellow {
    @apply btn text-stone-50 bg-yellow-600 hover:text-stone-50 hover:bg-yellow-700 active:bg-yellow-700 active:outline active:outline-4 active:outline-yellow-200;
  }

  .btn-red {
    @apply btn text-stone-50 bg-red-600 hover:text-stone-50 hover:bg-red-700 active:bg-red-700 active:outline active:outline-4 active:outline-red-200;
  }

  .distance-label-container {
    background: none;
    border: none;
    pointer-events: none;
  }

  .distance-label-container div {
    transform: translateY(-50%);
    min-width: max-content;
  }

  .js-loading .sidebar-initial-state {
    @apply lg:w-20 !important
  }

  .js-loading .content-initial-state {
    @apply lg:pl-20 !important
  }

  .js-loading .sidebar-text {
    @apply hidden
  }

  .initial-render {
    @apply transition-all duration-1000 ease-in-out
  }

  .toggle-transition {
    @apply transition-all duration-300 ease-in-out
  }

  .sidebar-text {
    @apply whitespace-nowrap transition-opacity duration-500 ease-in-out
  }

  .sidebar-collapsed .sidebar-text {
    @apply hidden m-0 p-0;
  }

  .sidebar-collapsed .group {
    @apply w-fit;
  }

  .sidebar-collapsed .hover\:bg-gray-700:hover {
    @apply w-fit;
  }

  .sidebar-collapsed .sidebar-lock {
    @apply opacity-0 pointer-events-none;
  }

  .sidebar-lock {
    @apply transition-opacity duration-300;
  }

  .dropdown-content {
    @apply absolute z-20 bg-white shadow-lg rounded-lg hidden w-32 transition-all duration-200 ease-in-out;
  }

  .dropdown-content:not(.hidden) {
    @apply block;
  }

  .dropdown-content.overlap-left {
    @apply translate-x-[calc(100%+8px)] ml-0;
  }

  .dropdown-content.overlap-right {
    @apply -translate-x-[calc(100%+8px)] mr-0;
  }

  .message {
    @apply transition-all duration-200 ease-in-out;
  }

  .message .dropdown-content.overlap-left ~ .message-content {
    @apply ml-8;
  }

  .message .dropdown-content.overlap-right ~ .message-content {
    @apply mr-8;
  }
}

.dropdown {
  @apply absolute z-20 mt-2 w-fit min-w-52 origin-top-right bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none;
}

.dropdown-link {
  @apply text-gray-500 flex items-center gap-2 py-2 px-3 hover:bg-gray-100 rounded-md text-sm font-semibold hover:text-gray-600 w-full;

  & svg {
    @apply text-gray-500;
  }
}

.dropdown-delete-btn {
  @apply text-red-600 flex items-center gap-2 py-2 px-3 text-sm font-semibold hover:bg-red-50 rounded-md w-full;
}

.dropdown-delete-btn:hover {
  @apply text-red-600;
}

.dropdown-link,
.dropdown-delete-btn {
  & svg {
    @apply size-5;
  }
}

.tools-link {
  @apply w-full block text-black hover:bg-sky-100 px-4 py-2 text-left hover:text-sky-600 rounded;
}

.active_tab {
  @apply border-indigo-500 text-gray-800 whitespace-nowrap border-b-[3px] pb-2.5;
}

.port_statuses {
  @apply flex flex-row justify-between;
}

.custom-height {
  height: calc(100vh - 8rem);
}

.map-height {
  height: calc(100vh - 4.5rem);
}

.no-y-padding {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.no-x-padding {
  padding-left: 4px !important;
  padding-right: 4px !important;
}

.equipment-link {
  @apply text-blue-500 hover:text-blue-600 font-semibold;
}

.ticket-link {
  @apply text-indigo-500 hover:text-indigo-600 font-semibold;
}

.user-link {
  @apply text-green-500 hover:text-green-600 font-semibold;
}

.tooltip {
  @apply invisible absolute;
}

.has-tooltip:hover .tooltip {
  @apply visible z-50;
}

.transform.rotate-180 {
  transform: rotate(180deg);
}

@layer utilities {
  .overflow-hidden-important {
    overflow: hidden !important;
  }
}
